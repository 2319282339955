@use "_Variables.scss";

.projects {
    & .projects__title {
        padding-top: 3em;
        display: block;
        text-align: center;
    }
    & .projects__ul {
        display: grid;
        list-style: none;
        & .projects__project {
            border-bottom: 3px solid black;
            & .project {
                text-align: center;
                width: 100%;
                height: 100%;
              
               
                @media only screen and (min-width: 1440px) {
                    .project__info {
                        padding-top: 3%;
                    }
                   
                }

                @media only screen and (max-width: 1440px) {
                    & .project__viewContainer {
                        display: flex;
                        row-gap: 2em;
                        align-items: center;
                        flex-direction: column;
                      }
                   
                }
               
                & .project__info {
                    padding-bottom: 3em;
                    grid-area: Info;
                    background-color: Variables.$Primary;
                    display: grid;
                    grid-template-rows: auto auto auto;
                    grid-template-areas: 
                    "Info"
                    "Showcase"
                    "Button";

                    & .project__info__container {
                        grid-area: Info;
                        & .project__type {
                            font-size: 0.8em;
                            padding-bottom: 1.5em;
                            color: Variables.$Accent;
                        }
                        & .project__title {
                            font-size: 1.5em;
                            padding-bottom: 0em;
                            color: #333;
                            font-weight: bold;
                        }
                        & .project__description {
                            color: Variables.$PrimaryText;
                        }
                    }
                    & .project__showCase {
                        grid-area: Showcase;
                        max-width: 100%;
                        box-sizing: border-box;
                        
                    }
                    & .project__viewContainer {
                        padding-top: 5em;
                        grid-area: Button;
                        display: flex;
                        
                        justify-content: space-around;
                        & button {
                            border: none;
                            background: Variables.$Secondary;
                            color: Variables.$SecondaryText;
                            font-size: 1.5em;
                            font-weight: bold;
                            padding: 1em 2em 1em 2em;
                            letter-spacing: 0.07em;                            
                        }
                        & button:hover {
                            cursor: pointer;
                            color: Variables.$PrimaryText;
                        }
                    }
                }
                
            }
            
        }
        & .projects__project:first-child {
            border-top: 3px solid black;
        }

    } 
 
   
}