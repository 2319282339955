@use "_Variables.scss";

.navbar {
    display: flex;
    line-height: 3em;
    color: Variables.$SecondaryText;
    justify-content: space-between;
    padding-left: 2em;
    padding-right: 2em;
    background-color: Variables.$Secondary;
   
    & .links {
      display: flex;
      line-height: 3em;
      justify-content: flex-end;
      
      align-items: center;
      & a {
        line-height: 3em;
        color: Variables.$SecondaryText;
        padding-right: 1em;
        padding-left: 1em;
        text-decoration: none;
      }  
      & a:hover {
        color: Variables.$Accent;  
      }
    }
  }