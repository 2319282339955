@import "slick-carousel/slick/slick.css";
@import "slick-carousel/slick/slick-theme.css";
.Carousel {
    padding-top: 3em;
    padding-bottom: 5em;

    box-sizing: border-box;
    height: auto;
    width: 100vw;
    justify-content: center;
 
}
.arrow {
    position: absolute;
    z-index: 10;
}
.slick-slide {
    display: flex !important;
    justify-content: center;
}
.slide img {
    width: 30em;
    height: 30em;
    opacity: 0.5;
}
.arrow svg {
    height: 3em;
    width: 3em;
}
@media only screen and (min-width: 1500px) {
    .slide img {
        width: 25em;
        height: 25em;
    }
}
@media only screen and (max-width: 1499px) {
    .slide img {
        width: 15em;
        height: 15em;
    }
}
@media only screen and (max-width: 1199px) {
    .slide img {
       width: 12em;
       height: 12em;
    }
    .arrow svg {
        height: 2em;
        width: 2em;
    }
}

.activeSlide img {
    transform: scale(1.2);
    opacity: 1;
}

.arrow:hover svg {
    transform: scale(1.2);
    cursor: pointer;
}
.next {
    left: 75%;
    top: 100%;
}
.prev {
    right: 75%;
    top: 100%;
}