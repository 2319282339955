
@use "styles/_Variables.scss";

@media only screen and (min-width: 2500px) {
 body {
  font-size: 1.5em;
 }
}
@media only screen and (max-width: 2499px) {
  body {
   font-size: 1.25em;
  }
 }
 @media only screen and (max-width: 1600px) {
  body {
   font-size: 1em;
  }
 }
 @media only screen and (max-width: 1200px) {
  body {
   font-size: 0.75em;
  }
 }
.App {
  text-align: left;
}
* {
  padding: 0px;
  margin: 0px;
  font-family: Variables.$FontDefault;
}
h1, h2, h3 {
  font-family: Variables.$FontHeader;
}
p {
  font-size: 1.1em;
}
body {
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 0px;
}
