@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

$Primary: #eee;
$PrimaryText: #666;
$Secondary: rgb(121, 1, 195);
$SecondaryText: rgb(255, 255, 255);
$Accent: rgb(9, 180, 134);
$BoldText: rgb(0,0,0);
$FontHeader: "Roboto Slab", serif;
$FontDefault: "Roboto", serif;