
.downArrow {
    padding-bottom: 1em;
    padding-top: 0em;
    text-align: center;
}

@keyframes direct {
    0% {
        transform: translateY(0em) scale(1);
        opacity: 95%;
    }


    100% {
        transform: translateY(0.5em) scale(1.1);
        opacity: 100%;
    }
}

.downArrow svg {
    opacity: 0%;
    margin-right: 20%;
    margin-left: 20%;
    animation: 1s alternate;
    animation-name: direct;     
    animation-iteration-count: infinite;
    width: 4em;
    height: auto;
}
