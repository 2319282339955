@use "./_Variables.scss";

.contact {
    
    padding-top: 2em;
    padding-bottom: 2em;
    background-color: Variables.$Primary;
    display: flex;
    flex-direction: column;
    text-align: center;
    row-gap: 1em;
    & div {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        text-align: center;
        & input, textarea {
            position: relative;
            width: 33%;
            box-sizing: border-box;
            padding-left: 1em;
            padding-right: 1em;
            padding-bottom: 0.5em;
            padding-top: 0.5em;
            line-height: 2em;
        }
        & textarea {
            height: 15em;
            resize: vertical;
        } 
        & button {
            background-color: Variables.$Accent;
            color: Variables.$SecondaryText;

        }
        & button:hover {
            cursor: pointer;
            color: Variables.$PrimaryText;
        }
        @media only screen and (min-width: 768px) {
            
            & label {
                right: auto;
                position: absolute;
                top: 0;
                padding-top: 0.5rem;
                bottom: 0em;
                line-height: 1.5em;
                left: calc(33% + 1em);
                z-index: 1;
                pointer-events: none;
            }
            & input:focus ~ label, input:not(:placeholder-shown) ~ label, textarea:focus ~ label, textarea:not(:placeholder-shown) ~ label{ 
                line-height: 1.5em;
                color: Variables.$SecondaryText;
                background: Variables.$Secondary;
                top: 0;
                padding-top: 0.5rem;
                bottom: 0;
                padding-right: 1em;
                padding-left: 1em;
                right: calc(33% + 0.5em);
                left: auto;
            }    
            & textarea ~ label {
                padding-top: 0.5rem;
            }
            & textarea:focus ~ label, textarea:not(:placeholder-shown) ~ label {
                padding-top: 0.5em;
                padding-bottom: 0.5em;
                max-height: 2em;
            }
            & button {
                width: 33%;
                align-self: center;
                padding-top: 1em;
                padding-bottom: 1em;
                padding-left: 1em;
                padding-right: 1em;
                line-height: 4em;
                & svg {
                    float: right;
                    width: 2em;
                    height: 2em;
                    display: inline;
                }
                h3 { 
                    float: left;
                    font-size: 2em;
                    display: inline;
                }
            }
           
        }
        @media only screen and (max-width: 767px) {
            flex-direction: column-reverse;
            row-gap: 1em;
            padding-right: 3em;
            padding-left: 3em;
            & input, textarea { 
                width: 100%;
            }
            & label {
                font: Variables.$FontHeader;
                font-weight: bold;
                font-size: 1.1em;
            }
            & button {
                width: 100%;
                height: 5em;
                margin: auto;
                align-self: center;
                text-align: left;
                & svg {
                    display: inline;
                    width: 1em;
                    height: 1em;
                    padding-right: 1em;
                    float: right;
                }
                & h3 {
                    font-weight: bold;
                    font: Variables.$FontHeader;
                    font-size: 1.3em;
                    display: inline;
                    padding-left: 1em;
                }
            }
            & button:hover {
                color: Variables.$PrimaryText;
            }


        }
    }

   
}

