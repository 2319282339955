@use "_Variables.scss";
.header {
    padding-left: 20%;
    padding-right: 20%;
    padding-top: 2em;
    display: grid;
    grid-template-columns: 80fr 20fr;
    grid-template-areas: 
    "Title" 
    "Description"
    "About";
    background-color: #fff;
    & .header__title {
        grid-area: Title;
        color: #333;
        padding-bottom: 1em;
    }

    & .header__description {
        grid-area: Description;
        font-size: 1.5em;
    }
    & .header__about {
        display: none;
        margin-top: 5em;
        grid-area: About;
        width: 10em;
        line-height: 2.5em;
        font-weight: bolder;
        font-size: 1.25em;
        border: none;
        background: Variables.$Accent;
        color: Variables.$SecondaryText;
    }

    & .header__about:hover {
        color: Variables.$PrimaryText;
        cursor: pointer;
    }
}